import { useRef, useState, useId, useContext, useEffect } from 'react';

import { EAS, SchemaEncoder } from '@ethereum-attestation-service/eas-sdk';
import { ethers } from 'ethers';

import { twMerge } from 'tailwind-merge';
import { string, object, array, InferType } from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import { useMagic } from '../../contexts/MagicContext';
import { useNavigate, useParams } from 'react-router-dom';

import TextField from '../../components/TextField/TextField';
import Typography from '../../components/Typography/Typography';
import Textarea from '../../components/Textarea/Textarea';
import TagsField from '../../components/TagsField/TagsField';
import Button from '../../components/Button/Button';
import FilePicker from '../../components/FilePicker/FilePicker';

import ProcessingUpload from '../../dialogs/ProcessingUpload/ProcessingUpload';
import SignatureRequest from '../../dialogs/SignatureRequest/SignatureRequest';
import GeneratingAttesation from '../../dialogs/GeneratingAttesation/GeneratingAttesation';
import Editor from '../../components/Editor/Editor';
import WhatIsAttesation from '../../components/WhatIsAttesation/WhatIsAttesation';

import ASSETS from '../../assets';
import { useFormik } from 'formik';
import { EditorContext } from '../../contexts/EditorContext';
import DocPreview from '../../components/DocPreview/DocPreview';
import {
  easContractAddress,
  easUId,
  magicRPC,
  privateKey as pKey,
} from '../../app.config';
import { addNewAttestaion } from '../../http';

const schema = object({
  title: string()
    .max(200, 'Title must not exceed 200 characters')
    .required('Title is required'),
  description: string()
    .optional()
    .max(500, 'Description must not exceed 500 characters')
    .label('Description'),
  tags: array().of(string()).max(5).optional().label('Tags'),
});

interface IInitialValues extends InferType<typeof schema> {}

const eas = new EAS(easContractAddress!);

const AddDocument = () => {
  const navigate = useNavigate();
  const slider = useRef<HTMLDivElement>(null);
  const editor = useContext(EditorContext);

  const { magic } = useMagic();

  const dispatch = useDispatch<Dispatch>();
  const [uid, setUid] = useState('');
  const [attestationValues, setAttestationValues] = useState<IInitialValues>();
  const [editorContent, setEditorContent] = useState('');
  const {
    attesationType: active,
    doc_hash,
    text_hash,
    loading,
  } = useSelector((state: RootState) => state.attesation);

  const { user } = useSelector((state: RootState) => state.auth);

  // test commit

  const formik = useFormik<IInitialValues>({
    initialValues: {
      title: '',
      description: '',
      tags: [],
    },
    validationSchema: schema,
    onSubmit: (values) => {
      setAttestationValues(values);
      handleSaveAndAttesation();
      setEditorContent(editor?.getHTML() || '');
    },
  });

  const [processDoc, setProcessDoc] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [openSignatureModal, setOpenSignatureModal] = useState(false);
  const [generateAttesation, setGenerateAttesation] = useState(false);
  const [show, setShow] = useState(false);
  const [doc, setDoc] = useState<File | null>(null);
  const [docType, setDocType] = useState<'pdf' | 'word'>('pdf');
  const [finalized, setFinalized] = useState(false);
  const picker = useId();
  // useEffect(()=>{
  //   dispatch.attesation.handleWordToPDF(doc);
  // },[])
  const handleSaveAndAttesation = async () => {
    dispatch.attesation.setDocHash('');
    dispatch.attesation.setTextHash('');
    if (active === 'text') {
      const content = editor?.getHTML();
      dispatch.attesation.handleGenerateTextHash(content || '');
      setOpenSignatureModal(true);
    } else {
      setProcessDoc(true);
    }
  };

  const handleEncodeData = async () => {
    try {
      // TODO: ALI AHMAD please remove private key from here.
      setFinalized(false);
      const provider = new ethers.BrowserProvider(magic?.rpcProvider as any);
      const signer = await provider.getSigner();
      const privateKey = pKey!;
      const senderProvider = new ethers.JsonRpcProvider(magicRPC);
      const sender = new ethers.Wallet(privateKey, senderProvider);

      await eas.connect(sender);
      const delegated = await eas.getDelegated();
      const schemaEncoder = new SchemaEncoder(
        'string attestation_type,string title,string description,string[] tags,bytes32 document_hash,bytes32 text_hash,bytes merkle_root,bytes nullifier_hash,bytes proof,bytes verification_level'
      );
      // console.log('encoding',text_hash,doc_hash);

      const _document_hash =
        active !== 'text'
          ? ethers.zeroPadBytes(ethers.getBytes('0x' + doc_hash), 32)
          : ethers.zeroPadBytes(ethers.getBytes('0x'), 32);
      const _text_hash =
        active === 'text'
          ? ethers.zeroPadBytes(ethers.getBytes('0x' + text_hash), 32)
          : ethers.zeroPadBytes(ethers.getBytes('0x'), 32);

      console.log(_document_hash, _text_hash);
      const encodedData = schemaEncoder.encodeData([
        {
          name: 'attestation_type',
          value: active === 'text' ? 'text' : 'doc',
          type: 'string',
        },
        { name: 'title', value: formik.values.title, type: 'string' },
        {
          name: 'description',
          value: formik.values.description || '',
          type: 'string',
        },
        { name: 'tags', value: formik.values.tags || [], type: 'string[]' },
        {
          name: 'document_hash',
          value: _document_hash,
          type: 'bytes32',
        },
        {
          name: 'text_hash',
          value: _text_hash,
          type: 'bytes32',
        },
        { name: 'merkle_root', value: '0x00', type: 'bytes' },
        { name: 'nullifier_hash', value: '0x00', type: 'bytes' },
        { name: 'proof', value: '0x00', type: 'bytes' },
        { name: 'verification_level', value: '0x00', type: 'bytes' },
      ]);

      const walletAddress = user?.walletAddress
        ? user?.walletAddress
        : await signer.getAddress();
      const response = await delegated.signDelegatedAttestation(
        {
          schema: easUId!,
          recipient: walletAddress, // signer.getAddress(), //to be handled
          expirationTime: BigInt(0),
          revocable: true,
          refUID:
            '0x0000000000000000000000000000000000000000000000000000000000000000',
          data: encodedData,
          deadline: BigInt(0),
          value: BigInt(0),
        },
        signer
      );

      console.log('response ====>', response);

      // const inputText = editorContent.substring(4,editorContent.length -5);
      // console.log('Text', editorContent.substring(4,editorContent.length -5));

      const formData = new FormData();
      if (active === 'document') {
        formData.append('file', doc!);
        formData.append('size', Math.floor((doc!.size || 0) / 1024).toString());
      } else {
        formData.append('text', editorContent);
      }

      formData.append('documentType', active === 'text' ? 'text' : docType);
      formData.append('encodedData', encodedData);
      // @ts-ignore
      formData.append('signature', JSON.stringify(response.signature));
      formData.append(
        'refUID',
        '0x0000000000000000000000000000000000000000000000000000000000000000'
      );
      formData.append(
        'originalUID',
        '0x0000000000000000000000000000000000000000000000000000000000000000'
      );

      const { data } = await addNewAttestaion(formData);
      setUid(data.UID);
      setFinalized(true);
      editor?.commands.setContent('');
    } catch (err: any) {
      console.log(err.message);
    } finally {
    }
  };

  const handleTagsChange = (tag: string) => {
    const tags = formik.values.tags || [];
    if (tags.includes(tag)) {
      tags.splice(tags.indexOf(tag), 1);
    } else {
      tags.push(tag);
    }
    formik.setFieldValue('tags', tags);
  };

  const handleChangeTab = (val: typeof active) => {
    dispatch.attesation.setAttesationType(val);
  };

  useEffect(() => {
    if (active === 'document' && doc) {
      const interval = setInterval(() => {
        setUploadProgress((prev) => (prev < 100 ? prev + 10 : 100));
      }, 500);
      return () => clearInterval(interval);
    }
    setUploadProgress(0);
  }, [doc]);

  useEffect(() => {
    formik.resetForm();
  }, [active]);

  const truncateName = (name: string, maxLength: number) => {
    if (name.length > maxLength) {
      return name.substring(0, maxLength) + '...';
    }
    return name;
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files?.length) {
      const file = e.currentTarget.files[0];
      console.log('file', file);
      if (file.name.endsWith('.docx') || file.name.endsWith('.doc')) {
        setDocType('word');
      } else {
        setDocType('pdf');
      }
      setDoc(file);
    }
  };

  useEffect(() => {
    if (editor) {
      editor.setEditable(true);
      editor.commands.setContent('');
    }
  }, [editor]);

  useEffect(() => {
    if (slider.current) {
      if (active === 'text') {
        slider.current.style.left = '3px';
      } else {
        slider.current.style.left = 'calc(50%)';
      }
    }
  }, [active]);

  return (
    <div className='flex-grow bg-white shadow-auth_box rounded-[15px] p-[50px] flex mb-[32px] gap-[50px] relative'>
      <div className='flex-1 border border-k-border overflow-hidden rounded-[12px] flex flex-col'>
        <div className='bg-white py-[10px] px-4 border-b border-k-border'>
          <div className='flex relative h-[52px] rounded-3xl bg-k-grey-bg border border-k-border'>
            <div
              className='flex-1 flex h-full justify-center items-center cursor-pointer'
              onClick={() => handleChangeTab('text')}
            >
              <Typography
                variant='h6'
                color={active === 'text' ? 'black' : 'grey'}
                className={twMerge(
                  'z-10 transition-all ',
                  active === 'text' ? 'semibold' : 'font-medium'
                )}
              >
                Text
              </Typography>
            </div>
            <div
              className='flex-1 flex h-full justify-center items-center cursor-pointer'
              onClick={() => handleChangeTab('document')}
            >
              <Typography
                variant='h6'
                color={active === 'document' ? 'black' : 'grey'}
                className={twMerge(
                  'z-10 transition-all ',
                  active === 'document' ? 'semibold' : 'font-medium'
                )}
              >
                Document
              </Typography>
            </div>
            <div
              ref={slider}
              className='slider w-[calc(50%_-_3px)] absolute left-[3px] top-[3px] h-[calc(100%_-_6px)] rounded-3xl bg-white border border-k-border transition-all duration-300 shadow-tab_shadow'
            ></div>
          </div>
        </div>
        {active === 'document' && (
          <div className={'flex-grow bg-k-grey-200 h-1'}>
            {doc ? (
              <div className='h-full flex flex-col items-center relative'>
                <DocPreview url={doc} docType={docType} />
                <label htmlFor={picker}>
                  <div className='absolute cursor-pointer bottom-[80px] left-[50%] translate-x-[-50%]'>
                    <Button
                      label='Replace File'
                      variant='primary'
                      leftIcon={ASSETS.upload_files}
                      className='pointer-events-none'
                    />
                  </div>
                </label>
              </div>
            ) : (
              <div
                className='h-full flex flex-col items-center pt-[88px] pb-[45px]'
                onDragEnter={() => setShow(true)}
              >
                <div className='ml-9'>
                  <img src={ASSETS.doc_preview} alt='' />
                </div>
                <div className='mt-auto flex flex-col items-center'>
                  <label htmlFor={picker}>
                    <div className='inline-block cursor-pointer'>
                      <Button
                        leftIcon={ASSETS.upload_files}
                        label='Upload File'
                        variant='primary'
                        role='grid'
                        className='pointer-events-none'
                      />
                    </div>
                  </label>

                  <div className='cursor-pointer' onClick={() => setShow(true)}>
                    <Typography
                      variant='body1'
                      color='grey'
                      className='mt-[35px] cursor-pointer'
                    >
                      Drag and drop file to upload.
                    </Typography>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {active === 'text' && (
          <div className='flex-grow bg-k-grey-200 h-1'>
            <Editor />
          </div>
        )}
      </div>
      <div className='flex-1 flex flex-col gap-5'>
        <Typography variant='h2'>Attestation Information</Typography>
        <Typography variant='h6' color='grey'>
          Add metadata to your attestation to make it easier to search for and
          review.
        </Typography>
        <div className='flex flex-col gap-5'>
          <TextField
            label='Attestation Name'
            name='title'
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.title && formik.errors.title
                ? formik.errors.title
                : ''
            }
            isNotCrossIcon={true}
          />
          <div>
            <Textarea
              label='Description (optional)'
              name='description'
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.description && formik.errors.description
                  ? formik.errors.description
                  : ''
              }
            />
            <div className='flex items-center justify-end mt-2'>
              <Typography variant='body1' color='grey'>
                {formik.values.description?.length}/500
              </Typography>
            </div>
          </div>
          <div>
            <TagsField
              label='Tags (optional)'
              tags={(formik.values.tags || []) as string[]}
              onTagChange={(v) => handleTagsChange(v)}
            />
            <div className='flex items-center justify-between mt-2'>
              <Typography variant='body1' color='grey'>
                Add a comma or hit enter after each tag.
              </Typography>
              <Typography variant='body1' color='grey'>
                {formik.values.tags?.length}/5
              </Typography>
            </div>
          </div>
        </div>
        <div className='mt-auto'>
          {active === 'document' && doc && (
            // TODO: Migrate this piece of code in a component
            <div
              className={twMerge(
                'flex items-center justify-between border border-k-border rounded-3xl py-[9px] px-[15px]',
                uploadProgress > 0 && uploadProgress < 100
                  ? 'gap-[0px]'
                  : 'gap-[10px]'
              )}
            >
              <div className='flex items-center gap-[10px]'>
                <img src={ASSETS.doc_icon} alt='' />
                <Typography
                  variant='body1'
                  className='text-k-dark-blue semibold'
                >
                  {uploadProgress > 0 && uploadProgress < 100
                    ? truncateName(doc?.name || '', 15)
                    : doc?.name}
                </Typography>
                {uploadProgress > 0 && uploadProgress < 100 && (
                  <Typography variant='body1' color='grey'>
                    ({Math.floor((doc?.size || 0) / 1024)}k)
                  </Typography>
                )}
              </div>
              {uploadProgress > 0 && uploadProgress < 100 && (
                <div className='flex-grow w-[117px] h-[6px] bg-gray-300 rounded-lg mx-2'>
                  <div
                    className='h-full bg-blue-500 rounded-lg transition-all'
                    style={{ width: `${uploadProgress}%` }}
                  />
                </div>
              )}
              {
                <div className='flex items-center gap-[10px]'>
                  {!(uploadProgress > 0 && uploadProgress < 100) && (
                    <Typography variant='body1' color='grey'>
                      ({Math.floor((doc?.size || 0) / 1024)}k)
                    </Typography>
                  )}
                  <img
                    src={ASSETS.close_icon}
                    className='ml-[5px] cursor-pointer'
                    alt=''
                    onClick={() => setDoc(null)}
                  />
                </div>
              }
            </div>
          )}
          <Button
            label='Save and Begin Attestation'
            variant='primary'
            className='w-full my-5'
            type='submit'
            onClick={() => formik.handleSubmit()}
            loading={loading}
          />
          <WhatIsAttesation />
        </div>
      </div>
      {show && (
        <div className='absolute w-full h-full left-0 top-0 z-20 p-5'>
          <FilePicker setShow={setShow} setFile={setDoc} />
        </div>
      )}
      <input
        type='file'
        name=''
        id={picker}
        className='hidden'
        accept='.pdf, .doc, .docx, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        onChange={handleFileChange}
      />
      {processDoc && (
        <ProcessingUpload
          text={editorContent}
          doc={doc}
          open={processDoc}
          setOpen={setProcessDoc}
          onClose={() => setOpenSignatureModal(true)}
        />
      )}
      {openSignatureModal && (
        <SignatureRequest
          open={openSignatureModal}
          attestationTitle={attestationValues?.title}
          doc={doc}
          setOpen={setOpenSignatureModal}
          onClose={() => {
            handleEncodeData();
            setGenerateAttesation(true);
          }}
        />
      )}
      {generateAttesation && (
        <GeneratingAttesation
          open={generateAttesation}
          setOpen={setGenerateAttesation}
          finalized={finalized}
          onClose={() => {
            setGenerateAttesation(false);
            if (uid) {
              if (active === 'document') {
                navigate(`/document/${uid}`);
              } else {
                navigate(`/inspect-text-attestation/${uid}`);
              }
            }
          }}
        />
      )}
    </div>
  );
};

export default AddDocument;
