import React, { FC, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { wordToPDF } from '../../http';
import { ClipLoader } from 'react-spinners';

interface IProps {
  url: string | File;
  docType?: 'word' | 'pdf';
  className?: string;
}

const DocPreview: FC<IProps> = ({ url, className, docType }) => {
  const [src, setSrc] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const handleFetchPdfVersion = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      if (url instanceof File) {
        formData.append('file', url);
      } else {
        formData.append('URL', url);
      }
      const { data } = await wordToPDF(formData);
      const response = await fetch(data.pdfURL);

      // Check if the request was successful
      if (!response.ok) {
        throw new Error(`Failed to fetch file. Status: ${response.status}`);
      }

      const fileBlob = await response.blob();

      const fileUrl = URL.createObjectURL(fileBlob);
      setSrc(fileUrl + '#toolbar=0');
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  // eslint-disable-next-line
  const fetchPdf = async (url: string) => {
    try {
      setLoading(true);
      const response = await fetch(url);
      const fileBlob = await response.blob();
      const fileUrl = URL.createObjectURL(fileBlob);
      setSrc(fileUrl + '#toolbar=0');
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (typeof url === 'string') {
      if (docType === 'pdf') {
        // fetchPdf(url);
        setSrc(url + '#toolbar=0');
      } else {
        handleFetchPdfVersion();
      }
    } else if (url instanceof File) {
      const mimeType = url.type;
      if (mimeType === 'application/pdf') {
        setSrc(URL.createObjectURL(url) + '#toolbar=0');
      } else {
        handleFetchPdfVersion();
      }
    }
    // eslint-disable-next-line
  }, [url]);

  return (
    <div className='h-full w-full -z-10 pointer-events-none'>
      {loading ? (
        <div className='w-full h-full flex items-center justify-center'>
          <ClipLoader size={40} color='#01032C' />
        </div>
      ) : (
        <>
          {src ? (
            <iframe
              title='PDF Preview'
              src={src}
              className={twMerge(
                'w-full h-full pt-[22px] px-[22px] bg-transparent border-none outline-none',
                className
              )}
              frameBorder={0}
              allow='cross-origin'
            />
          ) : (
            <div className='w-full h-full flex items-center justify-center'>
              <p>Unable to preview this PDF.</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(DocPreview);
