import { apiUrl, authKey } from '../app.config';
import axios from 'axios';
import CONSTANTS from '../contants';
import { IAttessation, IUser } from '../types/interfaces';
import { IAttestationById } from '../types/api';

const api = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  config => {
    const token = localStorage.getItem(CONSTANTS.token);
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error?.response && error?.response?.status === 401) {
      localStorage.clear();
      if (window.location.pathname !== '/') window.location.pathname = '/';
    }
    return Promise.reject(error);
  }
);

export default api;

export const onBoarding = (token: string) =>
  api.post<{ message: string; access_token: string }>('/v1/user/onboarding', {
    token,
  });

export const createHashWithDoc = (data: FormData) =>
  api.post<{ hash: string }>('/v1/user/uploadDocToCreateHash', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const wordToPDF = (data: FormData) =>
  api.post<{
    defaultPdfURL: string;
    message: string;
    pdfURL: string;
    resultStatus: boolean;
  }>('/v1/user/convertWordToPdf', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'x-api-key': authKey,
    },
  });

export const createHashWithText = (text: string) =>
  api.post<{ hash: string }>('/v1/user/uploadTextToCreateHash ', { text });

export const searchAttestation = (input?: string | null) => {
  const requestBody = input ? { input } : {};
  return api.post('/v1/attestation/search', requestBody);
};

export const getCurrentUser = () => api.get<{ user: IUser }>('/v1/user/');

export const getAllAttesations = () =>
  api.get<{ message: string; attestationData: IAttessation[] }>(
    '/v1/attestation/allAttestations'
  );

export const getAttestationByUid = (uid: string) =>
  api.get<IAttestationById>(`/v1/attestation/inspect/${uid}`);

export const compareDocument = (data: FormData) =>
  api.post<{
    documentComparisonResult: boolean;
    hash: string;
    message: string;
  }>('/v1/attestation/compareDocument', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'x-api-key': authKey,
    },
  });

export const addNewAttestaion = (data: FormData) =>
  api.post<{ message: string; UID: string }>(
    '/v1/attestation/addNewAttestation',
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );

export const updateUser = (data: { firstName: string; lastName: string }) =>
  api.patch<{ message: string; user: IUser }>('/v1/user/updateUser', data);

export const getMyAttestations = () =>
  api.get<{ message: string; attestationData: IAttessation[] }>(
    '/v1/attestation/myAttestations'
  );

export const getKPMGScan = () =>
  api.get<{ message: string; attestationData: IAttessation[] }>(
    '/v1/attestation/KPMGScan'
  );

export const getAttestationViaUID = (uid: string) =>
  api.get<{ message: ''; attestationData: IAttessation }>(
    `/v1/attestation/attestationByUID/${uid}`,
    {
      headers: {
        'x-api-key': authKey,
      },
    }
  );

export const emailVerification = (email: string) =>
  api.post<{ message: string; status: boolean }>(
    '/v1/user/emailVerification',
    {
      email: email,
    },
    {
      headers: {
        'x-api-key': authKey,
      },
    }
  );
